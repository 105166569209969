::-webkit-scrollbar{
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

a,
a:focus,
a:active,
a:hover,
button:focus,
button:active,
button:hover {
    color: var(--textcolor);
    text-decoration: none;
}


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundcolor);
  color: var(--textcolor);
  transition: background-color .2s, color .2s;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6,p,span{
  white-space: pre-line;
}

.capitalize{
  text-transform: capitalize;
}
.bold{
  font-weight: bold;
}
