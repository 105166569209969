@import "../../../../assets/variables.scss";
.vdjtitle{
    @media (max-width: $breakpoint){
        text-align: center;
        display: flex;
        width: 100%;
    }
    span{
        font-size: 2.3rem;
        @media (min-width: $breakpoint){
            font-size: 5rem;
        }
        @media (max-width: $breakpoint){
            flex: 1;
        }
    }
    img{
        height: 5rem;
        margin-left: 20px;
        margin-bottom: 30px;
    }
}