@mixin shadow{
    box-shadow: 0 0 15px rgba(0, 0, 0,.30), 0 0 25px rgba(0, 0, 0,.40);
}
@mixin light-shadow{
    box-shadow: 0 0 5px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.18);
}
@mixin inset-shadow{
    box-shadow: 0 0 5px rgba(0, 0, 0,.15) inset, 0 0 10px rgba(0, 0, 0,.25) inset;
}
$mainbackgroundcolor : #d3eed3;
$maincolor: #000;
$secondbackgroundcolor : #252525;
$vdjcolor: #4CAF50;
$vdjcolorlight: #d3eed3;
$vdjcolortextwhitebg: #95CF95;
$secondcolor: #fff;
$breakpoint : 950px;