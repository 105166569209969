.lang{
    display: flex;
    align-items: center;
    cursor: pointer;
    &__svg{
        height: 16.5px;
        margin-left: 4px;
        img{
            height: 100%;
        }
    }
    &__arrow{
        margin-left: 8px;
    }
}
.mainlang{
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}