.page-anim{
    &-enter{
        opacity: 0;
        &-active{
            opacity: 1;
            transition: opacity .3s;
        }
    }
    &-exit{
        opacity: 1;
        &-active{
            opacity: 0;
            transition: opacity .3s;
        }
    }
}



.page{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    &Container{
        position: relative;
    }
}