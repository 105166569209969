.mobileLang{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 100%;
    &__text{
        font-size: 1.5rem;
    }
    &__flags{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex: 1;
    }
    &__item{
        height: 30px;
        transition: transform .2s;
        img{
            height: 100%;
        }
        &.notSelected{
            transform: scale(.8);
        }
    }
}