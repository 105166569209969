.header{
    box-shadow: -1px 2px 28px rgba(0,0,0,0.38), -2px 2px 10px 0px rgba(0,0,0, .04);
    margin-bottom: 30px;
    min-height: 60px;
    position: relative;
    z-index: 999;
    &__row{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__line{
        display: flex;
        justify-content: space-evenly;
        width: 46%;
        &--item{
            text-align: center;
            font-size: 1.75rem;
            position: relative;
            padding: 6px;
            &::before{
                content: " ";
                width: 0px;
                height: 100%;
                height: -webkit-fill-available;
                background: #97cf96b0;
        
                transition: width .5s cubic-bezier(0.42, 0, 0, 1);
        
                position: absolute;
                bottom: 0;
                transform: translateX(-9px);
                
                z-index: -1;
            }
            &:hover::before{
                    width: calc(100% + 6px);
            }
        }
    }
    &__logo{
        width: 8%;
        margin-bottom: 10px;
        img{
            width: 100%;
        }
    }
}