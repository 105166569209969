@import '../../../../assets/variables.scss';
.sectionTitle{
    background-color: $mainbackgroundcolor;
    color: $maincolor;
    padding: 10px 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.1rem;
    @media (min-width: $breakpoint){
        font-size: 1.4rem;
    }
}