@import '../../../../assets/variables.scss';

.error{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__content{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        width: 50%;
        position: relative;
        @media (max-width: $breakpoint){
            flex-direction: column-reverse;
            width: 90%;
            margin-top: 10px;
        }
        &--main{
            font-size: 2rem;
            text-align: center;
            margin-bottom: 20px;
            @media (min-width: $breakpoint){
                margin-right: 250px;
                font-size: 2.6rem;
                text-align: initial;
            }
        }
        &--error{
            font-size: 3rem;
            margin-top: 40px;
        }
        &--explanation{
            font-size: 1.3rem;
        }
        &--svg{
            width: 20vw;
            @media (max-width: $breakpoint){
                margin: auto;
                margin-bottom: 20px;
            }
        }
    }
}