@import "../../../assets/variables.scss";
.mobileHeader{
    height: 7vh;
    min-height: 45px;
    margin-bottom: 40px;
    transition: max-height .3s, min-height .3s;
    
    
    &.deployed{
        max-height: 10vh;
    }
    &.hidden{
        max-height: 0;
        min-height: 0;
        overflow: hidden;
    }
    &__mainline{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 0 10px rgba(0, 0, 0,.20), 0 0 20px rgba(0, 0, 0,.30);
        position: relative;
        z-index: 15;
        &--logo{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 7px;
            box-sizing: border-box;
            a {
                height: 100%;
                img{
                    height: 100%;
                }
            }
        }
        &--deploy{
            font-size: 2.5rem;
            margin: 0 18px;
        }
    }
    &__deployedline{
        position: relative;
        z-index: 10;
        background-color: #fff;
        transition: max-height .4s;
        box-shadow: 0 4px 5px rgba(0,0,0,.3);
        overflow: hidden;
        &--link{
            margin: 7px 0 7px 20px;
            font-size: 1.5rem;
        }
        &.hidden{
            max-height: 0;
        }
        &.deployed{
            max-height: #{"max(25vh, 300px)"};
        }
    }
}